import $ from 'jquery';

// patch jquery for performance
const patchJQuery = (jQuery: JQueryStatic) => {
    jQuery.event.special.touchstart = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchstart", handle as unknown as EventListener, { passive: !ns.includes("noPreventDefault") });
        }
    };
    jQuery.event.special.touchmove = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchmove", handle as unknown as EventListener, { passive: !ns.includes("noPreventDefault") });
        }
    };
    jQuery.event.special.wheel = {
        setup: function( _, ns, handle ){
            this.addEventListener("wheel", handle as unknown as EventListener, { passive: true });
        }
    };
    jQuery.event.special.mousewheel = {
        setup: function( _, ns, handle ){
            this.addEventListener("mousewheel", handle as unknown as EventListener, { passive: true });
        }
    };

    return jQuery;
}

const patchedJquery = patchJQuery($);

export { $ };